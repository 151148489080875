import api from '@/api/api'
const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

export default class Parameter {
  get = async (token, parameter) => {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_parameter`
    const apiUrl = `${host}${resource}`

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: token,
      parameter: parameter
    }
    try {
      return await api.httpGet(apiUrl, config, params); 
    } catch (error) {
      throw error.response.data.mensaje;
    }
  }
}