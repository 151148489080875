<template>
<div class="mx-auto">
  <Loading :isLoading= "this.isLoading" />
  <div>
    <p class="title">Pago de parte social Inscripción FONCABSA</p>
    <p class="description md:text-center sm:text-left">
      <!--Para realizar el pago de su inscripción a FONCABSA puede hacerlo por medio de transferencia bancaria
      o pago en banco con la siguiente referencia:-->
      Para realizar el pago de su inscripción a FONCABSA puede hacerlo por medio de pago en banco
    </p>

    <div>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Banco:
      </p>
      <p class="subtitleValue flex md:justify-center justify-left" style="margin-top:0px;">
        ACTINVER SA
      </p>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        CLABE:
      </p>
      <p class="subtitleValue flex md:justify-center justify-left" style="margin-top:0px;">
        133180000127863072<br>
        <a v-on:click="copy('133180000127863072','CLABE')" style="margin-top:4px;margin-left:3px"><img src="@/assets/copy.png" class="img-responsive" style="width:20px; height:20px"></a>
      </p>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Nombre:
      </p>
      <p class="subtitleValue" style="margin-top:0px;display:flex;justify-content:center;">
        FONCABSA S.C DE A.P. DE R.L DE C.V.
      </p>
      <p class="subtitle" style="margin-top:15px;margin-bottom:0px;">
        Cantidad a depositar:
      </p>
      <p class="subtitleValue flex md:justify-center justify-left" style="margin-top:0px;">
        ${{priceEnrollPaymentFee}}
      </p>
      <br>
      <a style="display:flex;justify-content:center;" href="javascript:window.print()"><img src="@/assets/print.png" class="img-responsive" style="width:40px; height:40px"></a>
    </div>
    
    <div style="display:flex;justify-content:center;margin-top:30px;">
      <div class="grid grid-cols-3 gap-4 container-grid">
        <div class="col-span-3">
          <input class="button_popups" type="button" value="Ir a la página principal" v-on:click="reedirect('/cuenta/principal')" />
        </div>
      </div>
    </div>
    <br>
  </div>
</div>
</template>



<script>
import('@/assets/css/stylesheet.css')
import Loading from "@/components/Loading/VueLoading.vue";
import {getTokenDecoden} from "@/helpers/tokenauth";
import Parameter from '@/classes/Parameter'

export default {
  data() {
    return {
      personId: null,
      admissionStatus: "",
      slideIndex: 1,
      isLoading: false,
      voucherUploaded: false,
      ccapi: process.env.VUE_APP_CCAPI,
      urlWeb: process.env.VUE_APP_URLWEB,
      confirmPayment: false,
      parameterObjectClass: null,
      priceEnrollPaymentFee: "0.00"
    }
  },
  name: 'ReferenciaDePagoComponent',
  props: {
    msg: String
  },
  components: {
    Loading
  },
  mounted() {
    this.loadInfo();
    this.parameterObjectClass = new Parameter();
    this.parameterObjectClass.get(sessionStorage.getItem("login"),"pago parte social").then((response)=>{
      this.priceEnrollPaymentFee = Number(response.data.response.parameter).toFixed(2);
    })
  },
  beforeMount() {
  },
  methods:{
    loadInfo() {
      var auth = getTokenDecoden();
      this.personId = auth.obj.person["id"];
    },
    Logout(){
      sessionStorage.clear();
      //localStorage.clear();
      // window.location.href = "/"
      this.$router.push({path:"/"});
    },
    reedirect(url) {
      // window.location.href = url;
      this.$router.push({path:url});
    },
    copy(text, name){
      var aux = document.createElement("input");
      aux.setAttribute("value", text);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);

      var swal = require('sweetalert2')
      var namee = name + " copiada correctamente."
      swal.fire({
          title: "Aviso",
          text: namee,
          icon: "success",
          confirmButtonColor: '#FEB72B',
      })
    }
  }
}
</script>

<style>
.button_popups {
  border-radius: 5px;
  background-color: #FEB72B;
  color: #fff;
  font-family: Roboto;
  font-weight:400;
  width: 100%;
  height: 40px;
  padding:10px;
  font-size:16px;
}

.button_popups_no_background {
  border-radius: 5px;
  border: 1px solid #222222;
  background-color: #fff;
  color: #222222;
  font-family: Roboto;
  font-weight:400;
  width: 100%;
  height: 40px;
  padding:10px;
  font-size:16px;
}

.container-grid {
  width: 100%;
}

.upload_button {
  border-radius: 5px;
  background-color: #DEA610;
  color: #fff;
  font-weight:bold;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size:14px;
}

.inputsForm {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.italic {
  font-style: italic;
}
.title {
  font-size:30px;
  font-family:Roboto;
  color: #BFD243;
  font-weight: 500;
  margin-top:0px;
  text-align:left;
}

.description {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 300;
  margin-top:0px;
  text-align:left;
}

.subtitle {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 300;
  text-align:left;
}

.subtitleValue {
  font-size:20px;
  font-family:Roboto;
  color: #000;
  font-weight: 500;
  text-align: left;
}
@media only screen and (min-width: 1024px) {
  .container-grid {
    width: 40%;
  }

  .title {
    text-align:center;
  }

  .description {
    text-align:center;
  }
  .subtitle {
    text-align: center;
  }

  .subtitleValue {
    text-align: center;
  }
}

</style>